
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterShopFaceRegistrationForm from 'components/organismos/master/labor/MasterShopFaceRegistrationForm';

const MasterShopFaceRegistrationPage = () => (
  <SidebarTemplate pageTitle="顔写真登録">
    <MasterShopFaceRegistrationForm />
  </SidebarTemplate>
);

export default MasterShopFaceRegistrationPage;
