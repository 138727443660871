/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useParams, useHistory } from 'react-router-dom';
import BlockUI from 'components/molecules/BlockUi';

const PeopleTenantPage: React.FC = () => {
  
  const { tenant } = useParams();
  const history = useHistory();
  if (tenant){
    localStorage.setItem('PEOPLE_TENANT', tenant);
    history.push("/");
  }
  
  return (
    <div>
    </div>
  );
}

export default PeopleTenantPage;
