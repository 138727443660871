import { useState, useCallback, useEffect } from 'react';
import { getSpecificStaff, postStaffPhoto } from 'api/staff';
import useToastNotification from 'hooks/useToastNotification';
import { useOrgTreesOptionsShopV1 } from 'hooks/useOrgTreesOptions';
import { useStaffsForOrgCodeOptions, useStaffsSelectBoxForOrgCodeOptions } from 'hooks/useStaffsOptions';
import { useDispatch } from 'react-redux';
import { uiAppBarActions } from 'modules/ui/appBar';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const useMasterShopFaceRegistrationForm = () => {
  // redux
  const dispatch = useDispatch();

  const [disableButtonCap, setDisableButtonCap] = useState(false);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [staff, setStaff] = useState();
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');
  const orgTreesOptions = useOrgTreesOptionsShopV1(true, functionCode.masterShopFaceRegistration, undefined, undefined);
  const [staffCode, setStaffCode] = useState<any>('');
  const [croppedImage, setCroppedImage] = useState<any>("");

  const staffsForOrgCodeOptions = useStaffsSelectBoxForOrgCodeOptions(orgCode, functionCode.masterShopFaceRegistration, 0, true);

  const [tempModalFile, setTempModalFile] = useState<any>('');
  const [initImage, setInitImage] = useState('');
  const { successNotification, errorNotification } = useToastNotification();
  const [beforeCroppedImage, setBeforeCroppedImage] = useState<any>('');

  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.masterShopFaceRegistration);

  const handleSubmit = async (
    url: string
  ) => {
    let blob = null; 
    if(url){
      blob = await fetch(url).then(r => r.blob());
    }
    setIsLoading(true);
    if (!staff) {
      setIsLoading(false);
      errorNotification('スタッフを選択してください。');
      return;
    }
    // if (!confirmModalOpen) {
    //   setConfirmModalOpen(true);
    //   return;
    // }
    // setConfirmModalOpen(false);
    let convertData: any = {};
    convertData = {
      'id.staffCode': staffCode,
      personalRole: staff?.personalRole,
      shopRole: staff?.shopRole,
      hqRole: staff?.hqRole,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      useSupportStaff: false,
    };

    if (blob) {
      convertData = {
        ...convertData,
        nameplate: '1.jpg',
        photo1: blob,
      };
    }

    try {
      const response = await postStaffPhoto(convertData);
      setIsLoading(false);
      if (!response.errors) {
        successNotification('登録しました。');
        let image = `${process.env.REACT_APP_PHOTO_BASE_URL}/${response.staff.id.companyCode}/${response.staff.id.staffCode}/${response.staff.nameplate || "1.jpg"}?${Date.now()}`;
        setCroppedImage(image);
        setInitImage(image);
        setTempModalFile(image);
        setBeforeCroppedImage(image);
        if(staffCode === sessionStorage.getItem('loginUser.staffCode')){
          dispatch(uiAppBarActions.changeAvatar(image));
        }
      }
    } catch (error) {
      setCroppedImage('');
      setInitImage('');
      setTempModalFile('');
      setBeforeCroppedImage('');
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        const err = error.response.data.message.replace(/<br>/gi, '\n');
        errorNotification(err);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  useEffect(() => {
    if (staffCode) {
      setIsLoading(true);
      getSpecificStaff(staffCode).then((response: any) => {
        setIsLoading(false);
        setStaff(response);
        if(response.nameplate){
          let image = `${process.env.REACT_APP_PHOTO_BASE_URL}/${response.id.companyCode}/${staffCode}/${response.nameplate || "1.jpg"}?${Date.now()}`;
          setCroppedImage(image);
          setInitImage(image);
          setTempModalFile(image);
          setBeforeCroppedImage(image);
        }else{
          setCroppedImage("");
          setInitImage("");
          setTempModalFile("");
          setBeforeCroppedImage("");
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffCode]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    errorNotification,
    handleSubmit,
    companyCode,
    staff,
    orgCode,
    setOrgCode,
    orgTreesOptions,
    staffCode,
    setStaffCode,
    staffsForOrgCodeOptions,
    confirmModalOpen,
    closeConfirmModal,
    isLoading,
    setIsLoading,
    disableButtonCap,
    setDisableButtonCap,
    croppedImage,
    setCroppedImage,
    tempModalFile,
    setTempModalFile,
    initImage,
    beforeCroppedImage,
    setBeforeCroppedImage,
    detailRole,
  };
};


export default useMasterShopFaceRegistrationForm;
